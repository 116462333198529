import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { RiCoinsLine, RiMenu2Line } from "react-icons/ri";
import { FaX } from "react-icons/fa6";
import { FiUsers, FiInfo, FiHelpCircle } from "react-icons/fi";

const Navbar = () => {
  const [menu, setMenu] = useState(false);

  const Option = ({ title, active, link }) => (
    <a href={link}
      // target="_blank"
      // rel="noopener noreferrer"
      className={`text-base font-medium p-3 px-4 rounded-lg ${
        active ? "text-primary/700 bg-primary/50" : "text-gray/700"
      }`}
    >
      {title}
    </a>
  );

  const MobileOption = ({ icon, title, link }) => (
    <a href={link} className="text-base font-medium text-gray/700 flex items-center gap-3" onClick={() => setMenu(false)}>
      <span className="text-2xl text-gray/500">{icon}</span>
      {title}
    </a>
  )

  return (
    <>
      <div className="p-5 py-4 lg:py-5 bg-base/30 border border-b-gray/200 flex justify-between lg:justify-center items-center">
        <img className="lg:mr-28 cursor-pointer" src={logo} alt="" />
        <div className="hidden lg:flex">
          {/* <Option title="Dashboard" active /> */}
          <Option title="Rewards" link="https://help.textrp.io/v/launch-pack-nfts/#chat-rewards" />
          <Option title="About" link="https://about.textrp.io" />
          <Option title="FAQs" link="#faq" />
          <Option title="Support" link="https://team.textrp.io/helpdesk" />
        </div>
        <button className="lg:hidden text-xl text-gray/500" onClick={() => setMenu(true)}>
          <RiMenu2Line />
        </button>
      </div>

      <div className={`lg:hidden h-full fixed z-10 top-0 right-0 bg-base/30 overflow-x-hidden transition-all ${menu ? "w-full" : "w-0"} flex`}>
        <div className="p-5 py-4 h-full w-full flex flex-col items-start gap-5">
            <img className="mb-5" src={logo} alt="" />
            <MobileOption icon={<RiCoinsLine />} title="Rewards" link="https://help.textrp.io/v/launch-pack-nfts/#chat-rewards" />
            <MobileOption icon={<FiInfo />} title="About" link="https://about.textrp.io" />
            <MobileOption icon={<FiHelpCircle />} title="FAQs" link="#faq" />
            <MobileOption icon={<FiUsers />} title="Support" link="https://team.textrp.io/helpdesk" />
        </div>
        <div className="p-5 h-full bg-[#492f6f] text-white">
            <button onClick={() => setMenu(false)}>
                <FaX />
            </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
