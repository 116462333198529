import React from "react";
import { FiLock } from "react-icons/fi";

const NotFound = () => {
  return (
    <>
      <div className="patternBg h-screen spacing py-5 lg:py-8 text-center flex flex-col justify-center items-center gap-2">
        <div className="text-primary/300 text-3xl bg-primary/25 p-3 rounded-full">
            <div className="bg-primary-text/50 p-3 rounded-full">
                <FiLock />
            </div>
        </div>
        <h3 className="text-lg text-primary/600 font-medium">No Login NFT</h3>
        <h1 className="text-4xl lg:text-5xl text-primary/900 font-semibold">TextRP is in closed beta.</h1>
        <h2 className="text-primary/500 text-base lg:text-xl font-medium mt-5">
            You may access to this app by holding a Launch Pack NFT in your <br className="hidden sm:block" /> account wallet.
        </h2>
        <div className="mt-12">
            <h3 className="text-primary/900 text-sm lg:text-base font-medium">Launch Packs are available here:</h3>
            <div className="mt-2 flex flex-col items-center sm:flex-row gap-4">
                <a href="https://xmart.art/collections/textrp-messaging/launchpacks/" className="btn">
                    Legacy edition
                </a>
                <a href="https://nftmarketplace.opulencex.io/collection/65728a53af22ad2462095556" className="btn text-primary/500 bg-primary-text/50 border-primary-text/50">
                    Opulence edition
                </a>
            </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
