import React from "react";
import logo from "../../assets/logo.png";
import { FaTwitter, FaGithub, FaDiscord } from "react-icons/fa6";

const Footer = () => {

    const FooterLinks = ({ title, options }) => { 
        return(
            <div className="flex flex-col gap-4">
                <h2 className="text-gray/400 text-sm font-semibold">{title}</h2>
                {options.map((item) => (
                    <a key={item.name} href={item.link} className="block">{item.name}</a>
                ))}
            </div>
        )
    }

    const siteMap = [
      {
        name: "Home",
        link: "/"
      },
      {
        name: "FAQ",
        link: ""
      },
      {
        name: "Pricing",
        link: ""
      },
      {
        name: "About",
        link: ""
      }
    ];

    const getToKnow = [
      {
        name: "User Guide",
        link: "https://help.textrp.io/v/user-guide/"
      },
      {
        name: "Data Sheet",
        link: "https://docs.google.com/document/d/15MPKDHg8y2jxm0vFsVbTMlTxgZWjg2u-v5mmsfyCCVQ/edit?usp=sharing"
      },
      {
        name: "Roadmap",
        link: ""
      },
      {
        name: "Terms of Use",
        link: "https://help.textrp.io/terms-of-use"
      },
      {
        name: "Privacy Policy",
        link: "https://help.textrp.io/privacy-policy"
      }
    ];

    const usefulLinks = [
      {
        name: "XUMM Wallet (App Store)",
        link: "https://apps.apple.com/us/app/xaman-wallet-formely-xumm/id1492302343"
      },
      {
        name: "XUMM Wallet (Google Play)",
        link: "https://play.google.com/store/apps/details?id=com.xrpllabs.xumm"
      },
      {
        name: "Buy Legacy Launch Packs",
        link: "https://xmart.art/collections/textrp-messaging/launchpacks/"
      },
      {
        name: "Buy Special Edition LPs",
        link: "https://nftmarketplace.opulencex.io/collection/65728a53af22ad2462095556"
      },
      {
        name: "Launch Pack Explorer",
        link: "https://bithomp.com/nft-explorer?issuer=TextRPLP"
      }
    ];
    
  return (
    <>
      <div className="spacing py-5 lg:py-10 text-primary/500 font-medium flex flex-row justify-between items-start flex-wrap gap-4">
        <div className="flex flex-col items-start gap-5">
            <img src={logo} alt="" />
            <h2>The unified messaging layer for the <br /> XRP Ledger.</h2>
        </div>
        <FooterLinks title="Site Map" options={siteMap} />
        <FooterLinks title="Get to Know TextRP" options={getToKnow} />
        <FooterLinks title="Useful Links" options={usefulLinks} />
      </div>
      <div className="spacing py-5 lg:py-10 bg-gray/50 text-gray/500 font-medium flex flex-col-reverse items-start md:flex-row md:justify-between md:items-center gap-5">
        <p>© 2023 TextRP. All rights reserved.</p>
        <div className="text-xl text-gray/400 flex items-center gap-5">
            <a href="https://twitter.com/textrpsms">
                <FaTwitter />
            </a>
            <a href="https://discord.gg/JD27rSBbCx" className="text-white bg-gray/400 text-lg p-0.5 rounded-md">
                <FaDiscord />
            </a>
            <a href="https://github.com/xurgedigital">
                <FaGithub />
            </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
