import React, { useState } from "react";
import "./index.css"
import { CiSettings } from "react-icons/ci";
import { IoArrowForwardSharp, IoClose } from "react-icons/io5";
import { TbCoins } from "react-icons/tb";
import { FiLink, FiLock, FiMinusCircle, FiPhone, FiPlus, FiPlusCircle  } from "react-icons/fi";
import { PiChat } from "react-icons/pi";
import {Xumm} from 'xumm';
import { getAddress, isInstalled, getPublicKey, signMessage } from "@gemwallet/api";
import { Modal } from "antd";
import xummLogo from '../../assets/Xumm logo.png'
import soloLogo from '../../assets/Solo logo.png'
import crossmarkLogo from '../../assets/Crossmark logo.png'
import gemLogo from '../../assets/Gem logo.png'
import { Link } from "react-router-dom";
// import sdk from '@crossmarkio/sdk';
const xumm = new Xumm(process.env.REACT_APP_XUMM_API_KEY);

const Home = () => {
  const [activeFeature, setActiveFeature] = useState("Consolidated Messaging");
  const [pricePlan, setPricePlan] = useState('');
  const [connectModal, setConnectModal] = useState(false);
  
  // xumm.user.account.then((res) => console.log(res,"THis is res")) // to get xumm user account
  // xumm.logout() to logout xumm user

  const xummLogin = () => {
    xumm.authorize()
    .then((res) => console.log(res, "Xumm Logged in"))
    .catch((err) => console.log(err, "Xumm Login Error"))
  }

  const gemLogin = () => {
    console.log("gem login clicked")
    isInstalled().then((response) => {
      if (response.result.isInstalled) {
        getPublicKey().then((response) => {
          console.log(`${response.result?.address} - ${response.result?.publicKey}`);
        });
        getAddress().then((response) => {
          console.log(response.result?.address);
        });
        // signMessage("test Message").then((response) => {
        //   console.log(response.result?.signedMessage);
        // });
      }
      else alert("Please install Gem Wallet to continue")
    });
  }

  // const crossmarkLogin = async () => {
  //   let { request, response, createdAt, resolvedAt } = await sdk.signInAndWait();
  //   console.log(response.data.address);
  // }

  const soloLogin = async () => {
    
  }

  const FeatureOption = ({option}) => {
    return(
      <button className={`btn flex-shrink-0 ${activeFeature === option ? "btn" : "bg-transparent border-transparent text-primary/500"}`} onClick={() => setActiveFeature(option)}>
        {option}
      </button>
    )
  }

  const XrplList = () => (
    <ul className="ml-2 list-disc list-inside">
      <li>Wallet login</li>
      <li>Message any XRP wallet</li>
      <li>P2P transactions in chat</li>
      <li>NFTs for customization and utility</li>
    </ul>
  )

  const services = [
    {
      type: "Intra-App Send",
      cost: "0.02"
    },
    {
      type: "XRPL Send",
      cost: "1"
    },
    {
      type: "Voice Message",
      cost: "0.5",
    },
    {
      type: "Intra-App Voice Call (Per Minute)",
      cost: "0.5"
    },
    {
      type: "Video Call (Per Minute)",
      cost: "0.75"
    },
    {
      type: "Discord Send",
      cost: "0.3"
    },
    {
      type: "WhatsApp Send",
      cost: "0.3",
      soon: true
    },
    {
      type: "X(Formerly Twitter) Send",
      cost: "0.3"
    },
    {
      type: "Slack Send",
      cost: "0.3",
      soon: true
    },
    {
      type: "LinkedIn",
      cost: "0.3",
      soon: true
    },
  ]

  return (
    <>
      {/* Modal */}
      <Modal 
      open={connectModal}
      closable={false}
      onCancel={() => setConnectModal(false)}
      footer={''}
      className="relative"
      >
        <>
          <button className="absolute -top-7 right-2 text-primary/100 text-2xl" onClick={() => setConnectModal(false)}>
            <IoClose />
          </button>
          <div className="text-gray/700 font-semibold text-center bg-primary/25 p-2 rounded-t-lg">
            Connect a wallet to login
          </div>
          <div className="p-8 px-10">
            <div className="flex flex-col gap-4">
              <button className="login-btn p-4 md:px-8">
                <img src={xummLogo} alt="" />
                Xaman (formerly Xumm)
              </button>
              <button className="login-btn p-4 md:px-8">
                <img src={soloLogo} alt="" />
                Solo Dex
              </button>
              <button className="login-btn p-4 md:px-8">
                <img src={crossmarkLogo} alt="" />
                Crossmark
              </button>
              <button className="login-btn p-4 md:px-8">
                <img src={gemLogo} alt="" />
                Gem
              </button>
            </div>
            <div className="text-center mt-8 flex flex-col justify-center items-center gap-2">
              <h2 className="text-gray/800 font-medium">Don’t have a wallet?</h2>
              <button className="btn">Create new wallet</button>
            </div>
          </div>
        </>
      </Modal>

      {/* Main UI */}
      <div className="patternBg spacing py-5 lg:py-8 text-center flex flex-col justify-center items-center gap-5">
        <button className="btn text-primary/500 border border-primary/25 bg-[#f3f2f4]">
            Beta Testing Ongoing <CiSettings />
        </button>
        <h1 className="text-4xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold leading-[54px] lg:leading-[100px] xl:leading-[120px] 2xl:leading-[120px] text-primary/400">TextRP: <span className="text-primary/900">All Your Messages in One Place</span>.</h1>
        <h2 className="text-primary/600 font-medium text-xl xl:text-2xl">Experience a New Era of Messaging with TextRP's Web3-Enabled Hub.</h2>
        <Link className="btn" to={"https://app.textrp.io/"}>
            Connect now <IoArrowForwardSharp />
        </Link>
        <div className="circle-pattern bg-primary/800 w-full rounded-3xl p-5 xl:p-9 flex flex-col justify-center items-center gap-5 mt-28 shadow-[0px_11px_52px_0px_rgba(121,49,232,0.25)]">
          <h2 className="text-white font-semibold text-2xl xl:text-4xl">Get 10 credits on your first login!</h2>
          <h3 className="text-primary/100 xl:text-2xl font-medium">Get First-time free  login bonus here </h3>
          <button className="btn">
            Claim credit <TbCoins />
          </button>
        </div>
      </div>

      <div className="spacing pb-5 lg:pb-10 pt-14 lg:pt-24 bg-primary/25 text-center">
        <h1 className="text-primary/800 font-semibold text-3xl lg:text-5xl">
          Key Features of <span className="text-primary/400">TextRP.</span>
        </h1>
        <div className="featureOptions flex lg:justify-center mt-10 lg:mt-20 overflow-scroll">
          <FeatureOption option="Consolidated Messaging" />
          <FeatureOption option="Secure Communication" />
          <FeatureOption option="XRPL Integration" />
          <FeatureOption option="Explore Communities" />
        </div>
        {activeFeature === "Consolidated Messaging" &&  <Feature icon={<PiChat />} title="Unified Messaging Hub" desc="Explore the convenience of a unified messaging hub. TextRP consolidates messages from your favorite platforms Twitter, Discord, WhatsApp*, Telegram*, and more. Manage, read, and send private messages from a single, organized inbox within the app." note="*coming soon" img={"conso.png"} mobImg={"mobConso.png"} />}
        {activeFeature === "Secure Communication" &&  <Feature icon={<FiLock />} title="Ironclad Privacy" desc="Your messages deserve the utmost privacy. TextRP ensures secure communication with end-to-end encryption, providing a safe and confidential messaging experience. Communicate freely, knowing your privacy is our top priority." img={"secureencrypt.png"} mobImg={"mobSecure.png"} />}
        {activeFeature === "XRPL Integration" &&  <Feature icon={<FiPhone />} title="Ledger Interactions" desc="Connect seamlessly, transact effortlessly, and personalize your experience with the power of the XRP Ledger." note={<XrplList />} img={"xrplpage.png"} mobImg={"mobXrpl.png"} />}
        {activeFeature === "Explore Communities" &&  <Feature icon={<FiLink />} title="Communities on TextRP" desc="Discover people and communities within TextRP’s groups, or broaden your perspective y linking with communities across different blockchains* and matrix platforms. Engage with a global network, enrich your experience with each interaction." note="*coming soon" img={"explore.png"} mobImg={"mobExplore.png"} />}
      </div>

      <div className="spacing py-14 lg:py-24 text-center">
        <h1 className="text-primary/800 font-semibold text-2xl lg:text-5xl leading-[35px] lg:leading-[75px]">
          <span className="text-primary/400">Enhance</span> Your Messaging Experience with <span className="text-primary/400">Feature Packs.</span>
        </h1>
        <h2 className="mt-5 text-primary/800 font-medium lg:text-xl">
          Unlock unique messaging channels and enhanced features <br className="hidden lg:block" /> with TextRP Feature Packs.
        </h2>
        <div className="w-full flex justify-center mt-5">
          <button className="btn">
            Get a feature pack <IoArrowForwardSharp />
          </button>
        </div>
        <div className="mt-10 -mr-6 xl:hidden flex gap-4 overflow-x-auto">
          <FeaturePack image={"pack1.png"} title="X bridge" />
          <FeaturePack image={"pack2.png"} title="Discord bridge" />
          <FeaturePack image={"pack3.png"} title="Lifetime Subscription" />
          <FeaturePack image={"pack4.png"} title="Community spaces" />
          <FeaturePack image={"pack5.png"} title="MMS" />
          <FeaturePack image={"pack6.png"} title="Video chat" />
          <FeaturePack image={"pack7.png"} title="Voice messaging" />
          <FeaturePack image={"pack8.png"} title="Developer access" />
        </div>
        <div className="hidden mt-8 bg-primary-text/50 p-8 rounded-3xl border border-primary/100 xl:inline-grid grid-cols-4 gap-8">
          <FeaturePack image={"pack1.png"} title="X bridge" />
          <FeaturePack image={"pack2.png"} title="Discord bridge" />
          <FeaturePack image={"pack3.png"} title="Lifetime Subscription" />
          <FeaturePack image={"pack4.png"} title="Community spaces" />
          <FeaturePack image={"pack5.png"} title="MMS" />
          <FeaturePack image={"pack6.png"} title="Video chat" />
          <FeaturePack image={"pack7.png"} title="Voice messaging" />
          <FeaturePack image={"pack8.png"} title="Developer access" />
        </div>
      </div>

      <div className="spacing py-14 lg:py-24 flex flex-col-reverse lg:flex-row gap-10">
        <div className="text-primary/800 text-base lg:text-lg font-medium lg:w-1/2 flex flex-col justify-center items-center text-center gap-4 lg:gap-8 lg:hidden">
          <p>With TextRP, the more credits you buy , the lower your cost per credit. Our tiered pricing structure is designed to provide greater discounts as you commit to more credits. This means more messages and calls for less!</p>
          <button className="btn w-fit">
            Choose bundle <IoArrowForwardSharp />
          </button>
        </div>
        <div className="lg:w-1/2 flex flex-col gap-6">
          <PriceOption plan="Starter" price="50" detail="3.00 (5.298 XRP)" pricePlan={pricePlan} setPricePlan={setPricePlan}/>
          <PriceOption plan="Pro" save="17" price="100" detail="5.00 (8.831 XRP)" pricePlan={pricePlan} setPricePlan={setPricePlan} />
          <PriceOption plan="Plus" save="20" price="250" detail="10.00 (17.662 XRP)" pricePlan={pricePlan} setPricePlan={setPricePlan} />
          <PriceOption plan="Ultimate" save="25" price="500" detail="15.00 (26.492 XRP)" pricePlan={pricePlan} setPricePlan={setPricePlan} />
        </div>
        <div className="text-primary/800 text-base lg:text-lg font-medium lg:w-1/2 flex flex-col text-center lg:text-start gap-4 lg:gap-8">
          <h1 className="text-primary/500 font-bold text-4xl lg:text-5xl leading-[54px] lg:leading-[75px]">Affordable and Flexible Pricing.</h1>
          <p>At TextRP, we offer transparent and cost-effective communication solutions. Our credit-based system means you only pay for what you use, with no hidden fees. Get credits in convenient bundles, ensuring savings as you scale up.</p>
          <p className="hidden lg:block">With TextRP, the more credits you buy , the lower your cost per credit. Our tiered pricing structure is designed to provide greater discounts as you commit to more credits. This means more messages and calls for less!</p>
          <button className="btn w-fit hidden lg:flex">
            Choose bundle <IoArrowForwardSharp />
          </button>
        </div>
      </div>

      <div className="spacing py-14 lg:py-24 flex flex-col lg:flex-row gap-10">
        <div className="lg:w-2/5 flex flex-col justify-between text-center lg:text-start">
          <div>
            <h1 className="text-gray/800 font-bold text-4xl lg:text-5xl">In-app Credits</h1>
            <h2 className="mt-5 text-primary/800 font-medium lg:text-xl">
              Credits are the currency for TextRP, consumed for each message sent or call initiated
            </h2>
          </div>
          <div className="text-gray/800 text-sm lg:text-lg font-medium lg:flex flex-col gap-4 hidden">
            <p>Choose the perfect bundle for all your needs and start connecting with ease. </p>
            <p>Ready to start? <a href="" className="text-primary/500 underline">Purchase credits</a> now and dive into seamless connectivity tailored for you.</p>
          </div>
        </div>
        <div className="bg-primary/25 border border-primary-text/50 drop-shadow p-4 lg:p-8 lg:w-3/5">
          <div className="text-primary/500 font-semibold text-lg lg:text-2xl flex justify-between">
            <h1>Service Type</h1>
            <h1>Cost (Units)</h1>
          </div>
          <div className="mt-8">
            {services.map((item, i) => (
              <div key={i}>
                <div className="text-gray/800 text-sm lg:text-lg font-medium flex justify-between items-center mt-4">
                  <div className="flex items-center gap-3">
                    <h2>{item.type}</h2>
                    {item?.soon && <span className="text-primary/500 text-xs font-semibold bg-primary-text/50 p-1 px-2 rounded-full">Coming Soon</span>}
                  </div>
                  <h2>{item.cost}</h2>
                </div>
                {i !== services.length - 1 && <div className="border border-primary-text/50 w-full mt-4"></div>}
              </div>
            ))}
          </div>
        </div>
        <div className="text-gray/800 text-sm font-medium flex flex-col gap-4 lg:hidden">
          <p>Choose the perfect bundle for all your needs and start connecting with ease. </p>
          <p>Ready to start? <a href="" className="text-primary/500 underline">Purchase credits</a> now and dive into seamless connectivity tailored for you.</p>
        </div>
      </div>

      <div id="faq" className="spacing py-5 lg:py-10 bg-primary/900 flex flex-col lg:flex-row lg:justify-between items-start gap-10">
        <div className="text-center lg:text-left">
          <h2 className="text-white font-semibold text-3xl lg:text-4xl xl:text-5xl leading-[45px] lg:leading-[55px] xl:leading-[72px]">Frequently Asked <br /> Questions.</h2>
          <h3 className="text-primary/100 text-lg font-medium mt-5">Everything you need to know about the product and billing. Can’t find the answer you’re looking for? Please chat to our team.</h3>
        </div>
        <div className="w-full flex flex-col gap-5">
          <FaqCard question="How does TextRP work?" answer="TextRP is a fusion of the XRPL and Matrix protocols, resulting in a Web3-enabled messaging hub that consolidates messages from various platforms into a single, organized inbox. With XRPL integration, you can send secure messages to any XRP address." />
          <FaqCard question="Is my personal information safe with TextRP?" answer="Absolutely! TextRP's innovative login process eliminates the need for personal information. Instead, users can use their XUMM wallet for secure and private communication." />
          <FaqCard question="What are Feature Packs, and how do they work?" answer="Feature Packs are non-fungible tokens (NFTs) that allow you to customize your messaging experience by holding in in your messaging wallet. Each provides access to your favorite messaging channels and enhanced features, making your communication truly one-of-a-kind." />
          <FaqCard question="What messaging platforms are supported by TextRP?" answer="Currently, TextRP supports Twitter and Discord, with future plans to bridge Telegram, WhatsApp, and more! You can easily manage messages from these platforms within the TextRP app." />
          <FaqCard question="How can I get started with TextRP?" answer="Easy! Connect to the app with your XUMM wallet, and start enjoying a unified messaging experience. It's that simple! Note: During our closed-beta period you must hold a Launch Pack NFT in your login wallet to access the app, available at utilityNFT.tech." />
          <FaqCard question="Is TextRP available for free?" answer="You get 10 FREE messaging credits on your first login, enough for up to 500 in-App messages! After that, credits are as low as $0.025 USD!" />
          <FaqCard question="Is there customer support available?" answer="Of course! Our User Guide is available, and a dedicated customer support team is ready to assist you with any questions or issues you may have. You can reach out at help.textrp.io" />
          <FaqCard question="What payment methods are accepted for premium features and Feature Packs?" answer="We accept XRP as payment, and will be accepting select XRPL tokens in the future." />
          <div className="flex justify-center lg:justify-end my-3 lg:mt-3">
            <a href="https://help.textrp.io/v/user-guide/faq" className="btn">
              <FiPlus /> More FAQs
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

//Components

const PriceOption = ({ plan, save, price, detail, pricePlan, setPricePlan }) => {
  return(
    <div className={` border border-gray/200 p-4 px-6 rounded-xl cursor-pointer ${pricePlan === plan ? "bg-primary/25" : "bg-gray/50"}`} onClick={() => setPricePlan(plan)}>
      <div className="flex items-center gap-2">
        <h2 className="text-gray/800 text-base lg:text-lg font-semibold">{plan}</h2>
        {save && <span className="bg-success/50 text-success/700 text-sm font-medium p-1 px-3 rounded-full">Save {save}%</span>}
      </div>
      <div className="mt-3 text-primary/500 font-semibold flex items-end gap-2">
        <h1 className="text-5xl">{price}</h1>
        <span className="text-lg">messaging credits</span>
      </div>
      <h2 className="mt-2.5 text-gray/700 font-medium text-lg">${detail}</h2>
    </div>
  )
}

const FaqCard = ({question, answer}) => {
  const [open, setOpen] = useState(true);
  return(
    <div className="bg-primary/800 p-5 rounded-2xl cursor-pointer" onClick={() => setOpen(!open)}>
      <div className="flex justify-between items-center gap-4">
        <h2 className="text-white text-lg lg:text-xl font-semibold">{question}</h2>
        <button className="text-primary/400 text-2xl">
          {open ? <FiMinusCircle /> : <FiPlusCircle />}
        </button>
      </div>
      {open && <div className="text-primary-text/50 font-medium mt-4 lg:mr-10">
        {answer}
      </div>}
    </div>
  )
}

const FeaturePack = ({ image, title }) => {
  return (
    <div className="feature-pack w-[255px] h-[297px] flex-shrink-0 flex flex-col justify-end items-center pb-5" style={{ backgroundImage: `url(https://textrpimages.s3.eu-central-1.amazonaws.com/images/${image})` }}>
      <div className="feature-pack-text text-white w-fit text-sm font-medium px-4 py-2">
        <h2>{title}</h2>
      </div>
    </div>
  );
};

const Feature = ({ icon, title, desc, note, img, mobImg }) => {
  return(
    <div className="mt-10 flex flex-col items-center lg:flex-row lg:justify-between lg:items-start gap-5">
      <div className="text-primary-text/700 text-left flex flex-col gap-3 lg:w-1/4 lg:mt-10">
        <div className="hidden lg:block relative bg-primary/400 text-white p-3 w-fit text-2xl rounded-lg">
          {icon}
          <div className="absolute top-[50%] left-[100%] border border-primary/400 w-[50vw] z-0"></div>
        </div>
        <h3 className="text-primary/400 font-bold text-lg lg:text-xl lg:mt-4">{title}</h3>
        <p className="text-sm font-medium">{desc}</p>
        <p className="text-sm font-medium">{note}</p>
      </div>
      <div className="hidden lg:block z-10 w-3/4 lg:-mr-16 xl:-mr-20">
        <img className="w-full h-full object-cover" src={`https://textrpimages.s3.eu-central-1.amazonaws.com/images/${img}`} alt="" />
      </div>
      <img className="lg:hidden border border-primary/500 p-1 rounded-md" src={`https://textrpimages.s3.eu-central-1.amazonaws.com/images/${mobImg}`} alt="" />
    </div>
  )
}